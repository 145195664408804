import { graphql } from 'gatsby';
import React from "react";
import { IGatsbyImageData } from 'gatsby-plugin-image';

import Layout from "../components/layout";
import Gallery from '@browniebroke/gatsby-image-gallery';

interface ImageSharpEdge {
  node: {
    childImageSharp: {
      thumb: IGatsbyImageData
      full: IGatsbyImageData
      meta: {
        originalName: string
      }
    }
  }
}

interface PageProps {
  data: {
    images: {
      edges: ImageSharpEdge[]
    }
  }
}

const GaleryPage: React.FC<PageProps> = ({ data }) => {
  const images = data.images.edges.map(({ node }) => ({
    ...node.childImageSharp,
    caption: node.childImageSharp.meta.originalName,
  }));

  return (
    <Layout name="galery">
      <div className="text-gray-500 flex flex-col md:flex-row p-2">
        <Gallery images={images} />
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query ImagesForGallery {
    images: allFile(
      filter: { relativeDirectory: { eq: "gallery" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 270
              height: 270
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
            meta: fixed {
              originalName
            }
          }
        }
      }
    }
  }
`

export default GaleryPage;
